// MUI
import Stack from "@mui/material/Stack";

// Components
import Typography from "../../common/components/Typography";
import Button from "../../common/components/Button";

// containers
import HeadBanner from "./containers/HeadBanner";
import FeatureSection from "./containers/FeaturesSection";
import ApplyForAccessSection from "./containers/ApplyForAccessSection";
import Footer from "./containers/Footer";

// utils
import { Colors } from "../../common/utils/constants";

const LandingPage = () => {
  return (
    <Stack>
      <HeadBanner />
      <FeatureSection />
      <ApplyForAccessSection />
      <Footer />
    </Stack>
  );
};

export default LandingPage;
