// MUI
import Stack from "@mui/material/Stack";
import CardMedia from "@mui/material/CardMedia";

// Components
import Typography from "../../../common/components/Typography";
import Button from "../../../common/components/Button";

// Utils
import { Colors } from "../../../common/utils/constants";

// images
import placeholderPerson from "../../../images/placeholder person image.png";

const ApplyForAccessSection = () => {
  return (
    <Stack
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridTemplateRows: "1fr",
        paddingX: "10%",
        marginBottom: 4,
      }}
    >
      <Stack
        width={477}
        height={477}
        marginTop={4}
        alignItems={"center"}
        justifyContent={"center"}
        sx={{
          borderRadius: "50%",
          overflow: "hidden",
          backgroundColor: Colors.lemon,
          gridColumn: "1/2",
          gridRow: "1/2",
        }}
      >
        <CardMedia
          component="img"
          src={placeholderPerson}
          sx={{ height: "100%", width: "100%", objectFit: "contained" }}
        />
      </Stack>
      <Stack
        spacing={2}
        justifyContent={"center"}
        sx={{
          backgroundColor: Colors.primary,
          padding: 3,
          gridColumn: "1/3",
          gridRow: "1/2",
          marginLeft: "400px",
          marginTop: "200px",
        }}
      >
        <Typography variant="medheading" sx={{ color: Colors.white }}>
          Be the first to test
        </Typography>
        <Typography variant="body" sx={{ color: Colors.white }}>
          Hi, I'm Louis Graham, the founder of the company. Book a demo call
          with me to become a beta tester for our app and kickstart your
          company. Apply for access below and I’ll be in touch to schedule a
          call.
        </Typography>

        <Stack width={190}>
          <Button variant="contained">Apply for access</Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ApplyForAccessSection;
