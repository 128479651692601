// MUI
import Stack from "@mui/material/Stack";

// Components
import Typography from "../../../common/components/Typography";
import Chip from "../../../common/components/Chip";

// utils
import { Colors } from "../../../common/utils/constants";

const FeatureBlock = ({ title, description, index }) => {
	return (
		<Stack spacing={2} alignItems={"center"}>
			<Chip label={index} />
			<Typography variant="smallheading" sx={{ textAlign: "center" }}>
				{title}
			</Typography>
			<Typography variant="body" sx={{ textAlign: "center" }}>
				{description}
			</Typography>
		</Stack>
	);
};

export default FeatureBlock;
