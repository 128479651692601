// import "@fontsource-variable/manrope";
import "@fontsource-variable/fraunces";
import "@fontsource/manrope";
import { createTheme } from "@mui/material/styles";
import { Colors } from "./constants";

const theme = createTheme({
  palette: {
    primary: {
      main: Colors.lemon,
      light: Colors.lightPrimary,
      dark: Colors.primary,
    },
    secondary: {
      main: Colors.lemon,
      light: Colors.white,
      dark: Colors.medPrimary,
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        fullWidth: false,
        sx: {
          fontFamily: "'Manrope'",
          textTransform: "none",
          fontSize: 18,
          fontWeight: 700,
          border: `1px solid ${Colors.lemon}`,
          borderRadius: 0,
          "&:hover": {
            backgroundColor: "transparent",
            color: Colors.lemon,
            "&.MuiButton-root": {
              width: "auto",
            },
          },
        },
      },
    },
  },
  typography: {
    fontFamily: "'Fraunces'",
    fontSize: 20,
    largeheading: {
      fontWeight: 600,
      fontStyle: "normal",
      fontSize: "80px",
      color: Colors.primary,
      lineHeight: "80px",
      fontFamily: "'Fraunces'",
    },
    medheading: {
      fontWeight: 600,
      fontSize: "40px",
      color: Colors.primary,
      lineHeight: "64px",
      fontFamily: "'Fraunces'",
    },
    smallheading: {
      fontWeight: 700,
      fontSize: "32px",
      color: Colors.primary,
      lineHeight: "32px",
      fontFamily: "'Fraunces'",
    },
    body: {
      fontWeight: 200,
      fontSize: "18px",
      lineHeight: "32px",
      color: Colors.primary,
      fontFamily: "'Fraunces'",
    },
  },
});

export default theme;
