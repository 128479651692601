// MUI
import Stack from "@mui/material/Stack";
import CardMedia from "@mui/material/CardMedia";

// components
import Typography from "../../../common/components/Typography";
import Button from "../../../common/components/Button";
import MenuBar from "./MenuBar";

// utils
import { Colors } from "../../../common/utils/constants";

// Images
import PhoneImage from "../../../images/phone.png";
import Background from "../../../images/Rectangle.png";

const HeadBanner = () => {
	return (
		<Stack
			alignItems={"center"}
			sx={{
				height: { xs: "auto", md: "730px" },
				position: "relative",
				zIndex: 1,
			}}
			boxSizing={"border-box"}
		>
			<MenuBar />
			<CardMedia
				component={"img"}
				src={Background}
				sx={{
					height: "100%",
					width: "100%",
					objectFit: "contained",
					position: "absolute",
					zIndex: 2,
				}}
			/>
			<Stack
				spacing={2}
				zIndex={3}
				alignItems={"center"}
				sx={{ paddingTop: "146px" }}
			>
				<Typography
					sx={{
						width: { xs: "auto", md: "635px" },
						textAlign: "center",
						color: Colors.white,
						fontSize: "80px",
					}}
					variant="h1"
				>
					Data tailored to your needs.
				</Typography>
				<Stack width={161}>
					<Button variant="contained">Learn more</Button>
				</Stack>
			</Stack>

			<CardMedia
				component={"img"}
				src={PhoneImage}
				sx={{
					height: 441,
					width: 767,
					objectFit: "contained",
					position: "absolute",
					bottom: -200,
					zIndex: 4,
				}}
			/>
		</Stack>
	);
};

export default HeadBanner;
