import { forwardRef } from "react";

// MUI
import MuiButton from "@mui/material/Button";
import Stack from "@mui/material/Stack";

const Button = forwardRef(
  (
    {
      children,
      fullWidth = false,
      variant,
      color = "primary",
      size = "small",
      ...rest
    },
    ref
  ) => {
    return (
      <MuiButton
        fullWidth={fullWidth}
        variant={variant}
        color={color}
        ref={ref}
        {...rest}
      >
        {children}
      </MuiButton>
    );
  }
);

export default Button;
