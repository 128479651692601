// MUI
import Stack from "@mui/material/Stack";

// components
import Typography from "../../../common/components/Typography";

// utils
import { Colors } from "../../../common/utils/constants";

const MenuBar = () => {
	return (
		<Stack
			justifyContent="space-between"
			sx={{ position: "absolute", top: 20, zIndex: 4 }}
			direction={"row"}
			width={"80%"}
			// marginX={"10%"}
		>
			<Typography variant="smallheading" sx={{ color: Colors.white }}>
				Eaze!
			</Typography>
			<Typography
				component={"a"}
				href="#"
				variant="smallheading"
				sx={{
					color: Colors.white,
					textDecoration: "none",
					transition: "0.2s color text-decoration ease-in-out",
					"&:hover": {
						color: Colors.lemon,
						textDecoration: "underline",
					},
				}}
			>
				Apply for access
			</Typography>
		</Stack>
	);
};

export default MenuBar;
