// MUI
import Stack from "@mui/material/Stack";

// Components
import Typography from "../../../common/components/Typography";

// Icons
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import XIcon from "@mui/icons-material/X";

// utils
import { Colors } from "../../../common/utils/constants";

const Footer = () => {
  return (
    <Stack spacing={2} alignItems={"center"} padding={4}>
      <Typography
        variant="largeheader"
        sx={{ fontWeight: 700, color: Colors.primary }}
      >
        Eaze!
      </Typography>
      <Stack
        direction="row"
        spacing={2}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <FacebookIcon sx={{ fontSize: 20, color: Colors.medPrimary }} />
        <InstagramIcon sx={{ fontSize: 20, color: Colors.medPrimary }} />
        <XIcon sx={{ fontSize: 20, color: Colors.medPrimary }} />
      </Stack>
    </Stack>
  );
};

export default Footer;
