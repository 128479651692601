// MUI
import Stack from "@mui/material/Stack";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";

// components
import FeatureBlock from "../components/FeatureBlock";

// utils
import { Colors } from "../../../common/utils/constants";

// images
import lightBackground from "../../../images/light background.png";

const FeatureSection = () => {
  const features = [
    {
      title: "Actionable Insights",
      description:
        "Optimize your products, improve customer satisfaction and stay ahead of the competition with our product data analytics.",
    },
    {
      title: "Data-Driven Decisions",
      description:
        "Optimize your products, improve customer satisfaction and stay ahead of the competition with our product data analytics.",
    },
    {
      title: "Always affordable",
      description:
        "Always affordable pricing that scales with your business. Get top-quality product data analytics services without hidden costs or unexpected fees.",
    },
  ];
  return (
    <Stack
      alignItems={"center"}
      sx={{
        height: "auto",
        width: "100%",
        backgroundColor: Colors.lightPrimary,
        zIndex: 0,
        marginTop: -10,
        paddingTop: 50,
        paddingBottom: 3,
      }}
    >
      <Grid container sx={{ width: "80%" }} spacing={3}>
        {features.map((feature, index) => (
          <Grid item xs={12} md={4} key={index}>
            <FeatureBlock
              title={feature.title}
              description={feature.description}
              index={index + 1}
            />
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};

export default FeatureSection;
