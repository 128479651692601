// theme
import { ThemeProvider } from "@emotion/react";
import theme from "./common/utils/theme";

// Pages
import LandingPage from "./pages/landing";
function App() {
	return (
		<ThemeProvider theme={theme}>
			<LandingPage />
		</ThemeProvider>
	);
}

export default App;
