import { forwardRef } from "react";

// MUI
import MuiTypography from "@mui/material/Typography";

const Typography = forwardRef((props, ref) => {
	return <MuiTypography ref={ref} {...props} />;
});

export default Typography;
