import { forwardRef } from "react";

// MUI
import MuiChip from "@mui/material/Chip";

//utils
import { Colors } from "../utils/constants";

const Chip = forwardRef(({ ...rest }, ref) => {
	return (
		<MuiChip
			ref={ref}
			sx={{
				height: 56,
				width: 56,
				backgroundColor: "transparent",
				borderRadius: "50%",
				fontFamily: "Fraunces",
				border: `1px solid ${Colors.primary}`,
			}}
			{...rest}
		/>
	);
});

export default Chip;
